import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { addTocart, removeFromCart } from '../../../redux/actions/cartAction';
import "./Cart.css";

import CheckoutModal from '../checkout/CheckoutModal.jsx';
import CartSingle from './CartSingle.jsx';
import ResumeOrderModal from './ResumeOrderModal.jsx';

const Cart = ({ selectedCustomer }) => {
    const dispatch = useDispatch();
    const { cart } = useSelector((state) => state.cart);

    // console.log(cart)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showResumeModal, setShowResumeModal] = useState(false);
    const handleCloseResumeModal = () => setShowResumeModal(false);

    const suspendedOrders = JSON.parse(localStorage.getItem("suspendedOrders")) || [];


    useEffect(() => {
        try {
            const savedCartItems = localStorage.getItem('cartItems');
            if (savedCartItems) {
                const cartItems = JSON.parse(savedCartItems);
                cartItems.forEach(item => dispatch(addTocart(item)));
            }
        } catch (error) {
            console.error('Error loading cart from localStorage', error);
            toast.error("Failed to load cart items.");
        }
    }, [dispatch]);

    // Persist cart changes to localStorage
    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cart));
    }, [cart]);

    // Function to update the price and/or quantity of a cart item
    const updateItem = (id, newPrice, newQuantity) => {
        const updatedItem = cart.find(item => item.inventoryId === id);
        if (updatedItem) {
            // Update the item with new price and quantity
            const updatedData = { ...updatedItem, discountedPrice: newPrice, qty: newQuantity };
            dispatch(addTocart(updatedData)); // Dispatch updated item
        }
    };

    // Calculate subtotal, total profit, tax, and total amount
    const subtotal = cart.reduce((acc, item) => {
        const priceToUse = item.discountedPrice > 0 ? item.discountedPrice : item.sellingPrice;
        return acc + priceToUse * item.qty;
    }, 0);

    const totalProfit = cart.reduce((acc, item) => {
        const priceToUse = item.discountedPrice > 0 ? item.discountedPrice : item.sellingPrice;
        const profitPerItem = priceToUse - item.costPrice;
        return acc + (profitPerItem * item.qty);
    }, 0);

    const tax = totalProfit * 0.16; // Assuming a 16% tax rate
    const total = subtotal;


    const handleSubmitOrder = () => {
        if (!selectedCustomer) {
            toast.error("Please select a customer before proceeding.");
            return;
        }

        const orderData = {
            cart,
            total,
            customer: selectedCustomer,
            tax,
        };

        localStorage.setItem("latestOrder", JSON.stringify(orderData));
        handleShow();
    };

    const removeFromCartHandler = (data) => {
        dispatch(removeFromCart(data));
    };

    const quantityChangeHandler = (data) => {
        dispatch(addTocart(data));
    };


    const handleResume = () => {
        // Initialize cart state from local storage
        const savedCartItems = localStorage.getItem('cartItems');

        // Check if there are items in the cart
        if (savedCartItems && JSON.parse(savedCartItems).length > 0) {
            toast.error("Please complete or hold the current transaction before proceeding.");
            return;
        }

        // Logic to show modal for resuming an order
        setShowResumeModal(true);
    };

    const resumeOrder = (order) => {

        // Get all suspended orders from localStorage
        const suspendedOrders = JSON.parse(localStorage.getItem("suspendedOrders")) || [];

        // Add each item from the resumed order to the cart
        order.cart.forEach(item => {
            dispatch(addTocart(item));
        });
        handleCloseResumeModal();

        // Remove the resumed order from the suspended orders array
        const updatedSuspendedOrders = suspendedOrders.filter((suspendedOrder) => suspendedOrder.id !== order.id);

        // Save the updated suspended orders array back to localStorage
        localStorage.setItem("suspendedOrders", JSON.stringify(updatedSuspendedOrders));

        toast.success("Order has been resumed.");
    };


    const handleSuspend = () => {
        if (!selectedCustomer) {
            toast.error("Please select a customer before suspending the order.");
            return;
        }

        // Create a unique identifier (e.g., a timestamp) for each suspended order
        const orderData = {
            id: Date.now(), // Use timestamp as a unique identifier
            cart,
            total,
            customer: selectedCustomer,
            tax,
            suspendedAt: new Date().toISOString() // Store the date the order was suspended
        };

        // Get existing suspended orders from localStorage, or initialize an empty array
        const suspendedOrders = JSON.parse(localStorage.getItem("suspendedOrders")) || [];

        // Add the new suspended order to the array
        suspendedOrders.push(orderData);

        // Save the updated suspended orders array back to localStorage
        localStorage.setItem("suspendedOrders", JSON.stringify(suspendedOrders));

        // Clear the cart after suspending the order
        cart.forEach(item => dispatch(removeFromCart(item)));

        // Notify the user
        toast.info("Order has been suspended.");
    };

    return (
        <>
            <div className="container-fluid p-2">
                <div className="row">
                    <div className="col-12" style={{ overflowX: "auto" }}>
                        <Table striped bordered hover className='table-container'>
                            <thead>
                                <tr>
                                    <th>Item Name</th>
                                    <th className="text-center">Qty</th>
                                    <th className="text-right">Price</th>
                                    <th className="text-right">Disc Price.</th>
                                    <th className="text-right">Total</th>
                                    <th className="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cart && cart.length > 0 ? (
                                    cart.map((item, index) => (
                                        <CartSingle
                                            key={index}
                                            data={item}
                                            quantityChangeHandler={quantityChangeHandler}
                                            removeFromCartHandler={removeFromCartHandler}
                                            updateItem={updateItem} // Pass update function as prop
                                        />
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" className="text-center" style={{ height: '30vh' }}>
                                            No items in cart
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='container-fluid'>
                    {cart.length > 0 && (
                        <div className="row justify-content-end mt-4 border-top">
                            <div className="col-8">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex justify-content-between">
                                        <span>Subtotal:</span>
                                        <span>Ksh.{subtotal.toFixed(2)}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between" style={{ fontSize: "14px" }}>
                                        <span className="fs-6">Tax (16%):</span>
                                        <span>Ksh.{tax.toFixed(2)}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between font-weight-bold">
                                        <span>Total:</span>
                                        <span>Ksh.{total.toFixed(2)}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}

                    <div className="row mt-3">
                        <div className="col-6 d-flex justify-content-center">
                            {
                                suspendedOrders.length > 0 && (
                                    <Button variant="info" onClick={handleResume} aria-label="Resume an order">
                                        Resume Order
                                    </Button>
                                )
                            }
                        </div>
                        {cart.length > 0 && (
                            <div className="col-6 d-flex justify-content-end">
                                <Button variant="danger" onClick={handleSuspend} aria-label="Suspend the order">
                                    Suspend
                                </Button>
                                <Button variant="primary" onClick={handleSubmitOrder} aria-label="Proceed to payment" className="ms-2">
                                    Pay Now
                                </Button>
                            </div>

                        )}
                    </div>

                    <CheckoutModal show={show} handleClose={handleClose} total={total} />
                </div>
            </div>
            {/* Use the new ResumeOrderModal component */}
            <ResumeOrderModal
                show={showResumeModal}
                handleClose={handleCloseResumeModal}
                suspendedOrders={suspendedOrders}
                resumeOrder={resumeOrder}
            />
        </>
    );
};

Cart.propTypes = {
    selectedCustomer: PropTypes.object,
};

export default memo(Cart);