import React, { useEffect, useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaArrowCircleRight, FaChartBar, FaChartLine, FaCreditCard, FaDollarSign } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllExpenses } from "../../../redux/actions/expenseAction";
import { getAllInventoryItems } from "../../../redux/actions/inventoryAction";
import { getAllSales } from "../../../redux/actions/saleAction";
import FormattedAmount from './FormattedAmount.jsx';

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { inventoryItems } = useSelector((state) => state.inventory);
  const { sales } = useSelector((state) => state.sale);
  const { expenses } = useSelector((state) => state.expense);

  useEffect(() => {
    if (user) {
      dispatch(getAllInventoryItems());
      dispatch(getAllSales());
      dispatch(getAllExpenses());
    }
  }, [dispatch, user]);

  const totalSales = useMemo(() => sales.reduce((acc, sale) => acc + sale.totalAmount, 0), [sales]);
  const totalExpenses = useMemo(() => expenses.reduce((acc, expense) => acc + expense.amount, 0), [expenses]);
  const stockValue = useMemo(() => inventoryItems.map(({ quantity, product }) => quantity * (product?.costPrice || 0)).reduce((acc, curr) => acc + curr, 0), [inventoryItems]);
  const estimatedSales = useMemo(() => inventoryItems.map(({ quantity, product }) => quantity * (product?.sellingPrice || 0)).reduce((acc, curr) => acc + curr, 0), [inventoryItems]);

  const totalValue = useMemo(() => sales && sales.flatMap(sale => sale.items.map(item => item?.qty * item?.product?.costPrice)).reduce((acc, value) => acc + value, 0), [sales]);
  const grossProfit = totalSales - totalValue;
  const netProfit = grossProfit - totalExpenses;

  return (
    <div className="container-fluid mb-lg-0">
      <div className="row">
        <div className="col-sm-6">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
        </div>
      </div>
      {/* Cards */}
      <Row className="gy-2">
        <Col lg={3} sm={6} xs={12}>
          <Card className="bg-success text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={stockValue} /></h2>
                  <p>Total Stock Value</p>
                </div>
                <FaDollarSign className="icon" />
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/sales" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col lg={3} sm={6} xs={12}>
          <Card className="bg-info text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={totalSales} /></h2>
                  <p>Total Sales</p>
                </div>
                <FaCreditCard className="icon" />
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/expenses" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col lg={3} sm={6} xs={12}>
          <Card className="bg-info text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={totalExpenses} /></h2>
                  <p>Total Expenses</p>
                </div>
                <FaCreditCard className="icon" />
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/expenses" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col lg={3} sm={6} xs={12}>
          <Card className="bg-success text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={grossProfit} /></h2>
                  <p>Gross Profit</p>
                </div>
                <FaDollarSign className="icon" />
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/sales" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col lg={3} sm={6} xs={12}>
          <Card className="bg-info text-white custom-panel">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={estimatedSales} /></h2>
                  <p>Estimated Sales</p>
                </div>
                <FaCreditCard className="icon" />
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/dashboard" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col lg={3} sm={6} xs={12}>
          <Card className={`custom-panel ${netProfit < 0 ? "bg-danger" : "bg-primary"} text-white`}>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2>Ksh.<FormattedAmount amount={netProfit} /></h2>
                  <p>{netProfit < 0 ? "Net Loss" : "Net Profit"}</p>
                </div>
                {netProfit < 0 ? (
                  <FaChartBar className="icon" />
                ) : (
                  <FaChartLine className="icon" />
                )}
              </div>
            </Card.Body>
            <Card.Footer>
              <Link to="/financials" className="text-white custom-text">
                More info <FaArrowCircleRight />
              </Link>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboard;