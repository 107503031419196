/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../../server/server";
import { getAllProducts } from "../../../redux/actions/productAction";
import { useDispatch, useSelector } from "react-redux";

const EditInventory = ({ show, handleClose, inventoryItem, fetchInventoryItems }) => {
    const dispatch = useDispatch();
    const { products } = useSelector((state) => state.product);

    const [formData, setFormData] = useState({
        product: "",
        color: "",
        size: "",
        quantity: 0,
    });

    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        dispatch(getAllProducts());

        // Set form data if editing an existing item
        if (inventoryItem) {
            setFormData({
                product: inventoryItem?.product._id,
                color: inventoryItem?.color,
                size: inventoryItem?.size,
                quantity: inventoryItem?.quantity
            });
            setSelectedProduct(inventoryItem.product); // Set selected product for editing
        } else {
            setFormData({ product: "", color: "", size: "", quantity: 0 });
        }
    }, [inventoryItem, dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevState) => {
            const newState = { ...prevState, [name]: value };

            if (name === "product") {
                const product = products.find((prod) => prod._id === value);
                setSelectedProduct(product);
                newState.color = ""; // Reset color
                newState.size = ""; // Reset size
            }

            return newState;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newFormData = {
            product: formData.product,
            color: formData.color,
            size: formData.size,
            quantity: formData.quantity,
        };

        try {
            await axios.put(`${server}/inventory/${inventoryItem._id}`, newFormData, { withCredentials: true });
            toast.success("Inventory item updated successfully");
            fetchInventoryItems();
            handleClose();
        } catch (error) {
            toast.error(error.response.data.message || "Error updating inventory");
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Inventory Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formProduct">
                        <Form.Label>Product</Form.Label>
                        <Form.Control
                            as="select"
                            name="product"
                            value={formData.product}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Product</option>
                            {products.map((product) => (
                                <option key={product._id} value={product._id}>
                                    {product.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    {selectedProduct && (
                        <>
                            <Form.Group controlId="formColor">
                                <Form.Label>Color</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="color"
                                    value={formData.color}
                                    onChange={handleChange}
                                // required
                                >
                                    <option value="">Select Color</option>
                                    {selectedProduct.colors.map((color) => (
                                        <option key={color} value={color}>
                                            {color}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>


                            <Form.Group controlId="formSize">
                                <Form.Label>Size</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="size"
                                    value={formData.size}
                                    onChange={handleChange}
                                // required
                                >
                                    <option value="">Select Size</option>
                                    {/* Loop through sizeDetails and render sizes */}
                                    {selectedProduct.sizeDetails.map((detail) => (
                                        detail.sizes.map((size) => (
                                            <option key={size} value={size}>
                                                {size}
                                            </option>
                                        ))
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </>
                    )}

                    <Form.Group controlId="formQuantity">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                            type="number"
                            name="quantity"
                            value={formData.quantity}
                            onChange={handleChange}
                            required
                            min="0"
                        />
                    </Form.Group>
                    <Button variant="primary" className="mt-2 float-end" type="submit">
                        Update
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditInventory;