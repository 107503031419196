/* eslint-disable react/prop-types */
import React from "react";
import { Button } from "react-bootstrap";

const ActionButtons = ({ onEdit, onDelete, onReconcile }) => {
    return (
        <div className="d-flex justify-content-between">
            <Button variant="warning" onClick={onEdit}>
                Edit
            </Button>
            <Button variant="danger" className="ms-2" onClick={onDelete}>
                Delete
            </Button>
            <Button variant="info" className="ms-2" onClick={onReconcile}>
                Reconcile
            </Button>
        </div>
    );
};

export default ActionButtons;
