/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createInventoryItem } from "../../../redux/actions/inventoryAction"; // Import your action to add inventory
import { getAllProducts } from "../../../redux/actions/productAction";
import { colors, sizes } from "../../../data"; // Import colors and sizes from data.js

const CreateInventory4 = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);

  const [formData, setFormData] = useState({
    product: "",
    size: "",
    colorQuantities: {},
  });

  // console.log(formData.colorQuantities)

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleQuantityChange = (color, size, quantity) => {
    setFormData((prevState) => ({
      ...prevState,
      colorQuantities: {
        ...prevState.colorQuantities,
        [color]: {
          ...prevState.colorQuantities[color],
          [size]: quantity === "" ? "" : quantity,
        },
      },
    }));
  };

  const calculateTotalQuantity = () => {
    return Object.values(formData.colorQuantities).reduce((total, colorQty) => {
      return total + Object.values(colorQty).reduce((sum, qty) => sum + (parseInt(qty) || 0), 0); // Convert to number, default to 0 if NaN
    }, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the payload with product, size, and color quantities
    const { product, size, colorQuantities } = formData;

    // Check if the product and size are selected
    if (!product || !size) {
      alert("Please select a product and size."); // Optionally, show a notification or alert if any field is not filled
      return;
    }

    try {
      // Dispatch the action to add the inventory item
      await dispatch(createInventoryItem({ product, size, colorQuantities }));
      handleClose(); // Close the modal after successful submission
      setFormData({ product: "", size: "", colorQuantities: {} }); // Reset form data
    } catch (error) {
      console.error("Failed to add inventory item:", error);
      // Optionally, show a notification for the error
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Add Inventory Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formProduct">
            <Form.Label>Product</Form.Label>
            <Form.Control
              as="select"
              name="product"
              value={formData.product}
              onChange={handleChange}
              required
            >
              <option value="">Select Product</option>
              {products.map((product) => (
                <option key={product._id} value={product._id}>
                  {product.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formSize" className="mt-3">
            <Form.Label>Size</Form.Label>
            <Form.Control
              as="select"
              name="size"
              value={formData.size}
              onChange={handleChange}
              required
            >
              <option value="">Select Size</option>
              {sizes.map((size, index) => (
                <option key={index} value={size}>
                  {size.charAt(0).toUpperCase() + size.slice(1)} {/* Capitalize size */}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>Color</th>
                {sizes.map((size, index) => (
                  <th key={index}>
                    {size.charAt(0).toUpperCase() + size.slice(1)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {colors.map((color) => (
                <tr key={color}>
                  <td>{color}</td>
                  {sizes.map((size) => (
                    <td key={size}>
                      <Form.Control
                        type="number"
                        min="0"
                        value={formData.colorQuantities[color]?.[size] || ""}
                        onChange={(e) => handleQuantityChange(color, size, e.target.value)}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>

          <Form.Group controlId="formTotalQuantity" className="mt-3">
            <Form.Label>Total Quantity</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={calculateTotalQuantity()} // Calculate total quantity
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-3 float-end">
            Add Inventory
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateInventory4;
